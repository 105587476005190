import React from "react";
import styled from "styled-components";
import { Headline3, Paragraph } from "../../../components/typography";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import SimpleSection from "../../../components/work/simple-section";
import { theme } from "../../../styles/styles";
import QuoteElement from "../../../components/quote-element/quote-element";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../../components/pimcore-image";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);
  return (
    <SectionWrapper>
      <ExampleHeaderSection
        headline={headline}
        subHeadline={getElementText(content[0])}
        primaryImage={getElementImage(content[1])}
        content={getElementText(content[2])}
        secondaryImage={getElementImage(content[3])}
        quote={{
          message:
            "We bring the structure, transparency, working methodologies and tools that help the Miele X teams to deliver high-tech quality products.",
          name: "Barbara",
          position: "Director of Operational Excellence and Information Management",
          modifier: "left-4 bottom-4 tablet:left-auto tablet:right-[22rem] tablet:bottom-64",
        }}
        prParagraph="4"
      />
      <SimpleSection
        headline={getElementText(content[4])}
        video={getElementVideo(content[5])}
        image={getElementImage(content[5])}
        content={getElementText(content[6])}
        marginTop="tablet:mt-0"
        start="7"
        padding="tablet:pr-8"
      />
      <Grid ref={wrapper}>
        <MediaCell1>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
        </MediaCell1>
        <MediaCell2>
          <QuoteElement
            message="We’re incredibly aware of the diverse needs of our colleagues. This is why we provide the environment where people can create at their very best."
            name="Anna"
            position="Office Manager"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-[23rem] tablet:right-96"
          />
          <PimcoreImage image={getElementImage(content[8])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell3>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
        </MediaCell3>
      </Grid>
    </SectionWrapper>
  );
};

export default ExamplesSection;

const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5 mb-14 tablet:mb-36",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(4, auto);
  }
`;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-4 mb-8 tablet:mb-0 relative",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 mb-4 tablet:mb-0 tablet:pr-6",
})``;
