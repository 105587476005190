import React from "react";
import styled from "styled-components";
import { PageElement } from "../../../graphql/page-common";
import WorkVideoElement from "../work-video-element";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";

interface WorkingSectionProps {
  intro: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ intro }) => {
  return (
    <Wrapper>
      <WorkVideoElement
        video={getElementVideo(intro[0])}
        image={getElementImage(intro[0])}
        content={getElementText(intro[1])}
      />
    </Wrapper>
  );
};

export default WorkingSection;
const Wrapper = styled.div.attrs({
  className: "-mt-64 tablet:-mt-72 relative z-20 scrollspy",
})``;
